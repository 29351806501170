import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/www/www/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/www/www/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/www/www/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/actions-runner/_work/www/www/src/app/insights/_components/content-card.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/home/runner/actions-runner/_work/www/www/src/components/common/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/www/www/src/components/contentful/contentful-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/actions-runner/_work/www/www/src/components/interaction/button.tsx");
