'use client'

import React, { type ReactNode, useCallback, useEffect, useState } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import Image from 'next/image'

const CarouselButton = ({
  children,
  onClick,
}: {
  children: ReactNode
  onClick: () => void
}) => {
  return (
    <button className={'flex pl-0 pr-4 xs:px-6'} onClick={onClick}>
      {children}
    </button>
  )
}

export const Carousel = ({ slides }: { slides: ReactNode[] }) => {
  const [viewportRef, embla] = useEmblaCarousel({ loop: true })
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollTo = useCallback(
    (index: number) => {
      embla?.scrollTo(index)
    },
    [embla],
  )

  useEffect(() => {
    if (embla === undefined) {
      return
    }

    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap())
    }

    embla.on('select', onSelect)

    return () => {
      embla.off('select', onSelect)
    }
  }, [embla, setSelectedIndex])

  return (
    <>
      <div className='relative flex h-full items-center'>
        <CarouselButton
          onClick={() => {
            scrollTo(selectedIndex - 1)
          }}
        >
          <Image
            src='/assets/svg/carousel-button.svg'
            alt='Arrow pointing right to control the carousel'
            width={20}
            height={200}
          />
        </CarouselButton>
        <div className='w-full overflow-hidden' ref={viewportRef}>
          <div className='grid w-full auto-cols-[100%] grid-flow-col items-center'>
            {slides.map((val, index) => (
              <div className='relative min-w-0 p-2' key={index}>
                <div className='relative overflow-hidden'>{val}</div>
              </div>
            ))}
          </div>
        </div>
        <CarouselButton
          onClick={() => {
            scrollTo(selectedIndex + 1)
          }}
        >
          <Image
            src='/assets/svg/carousel-button.svg'
            alt='Arrow pointing right to control the carousel'
            width={20}
            height={200}
            className='-scale-x-100'
          />
        </CarouselButton>
      </div>
      <div className='mt-4 flex justify-center'>
        {slides.map((_, index) => (
          <button
            key={index}
            className={`mx-4 h-3 w-3 rounded-full ${
              index === selectedIndex ? 'bg-bgRed' : 'bg-gray-600'
            }`}
            onClick={() => {
              scrollTo(index)
            }}
          ></button>
        ))}
      </div>
    </>
  )
}
