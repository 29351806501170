/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
'use client'

import Loader from '@components/common/loader'
import styles from './button.module.css'
import { type RouteType } from 'next/dist/lib/load-custom-routes'
import NextLink, { type LinkProps } from 'next/link'
import {
  type DetailedHTMLProps,
  type AnchorHTMLAttributes,
  forwardRef,
} from 'react'

type ButtonSize = 'sm' | 'md' | 'lg'

interface PlainButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'color'
  > {}

interface LinkButtonProps extends Omit<LinkProps<RouteType>, 'color'> {}

interface ExternalLinkButtonProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  external: boolean
}

interface CommonProps {
  /**
   * Class name of the button
   */
  className?: string
  /**
   * Content of the button
   */
  children?: React.ReactNode
  /**
   * Size of the button
   */
  size?: ButtonSize
  /**
   * Variant of the button
   */
  variant?: 'contained' | 'text' | 'outlined'
  /**
   * Colour of the button
   */
  colour?: 'primary' | 'secondary' | 'secondary-contrast' | 'white'
  /**
   * Should the button have the animations?
   */
  animate?: boolean
  /**
   * Show button full width
   */
  fullWidth?: boolean
  /**
   * Show loading state
   * @default false
   */
  loading?: boolean
  /**
   * Disable button
   * @default false
   */
  disabled?: boolean

  /**
   *
   * @returns
   */
  onClick?: () => void
}

export type V2ButtonProps = (
  | LinkButtonProps
  | PlainButtonProps
  | ExternalLinkButtonProps
) &
  CommonProps

type StyleProps = Pick<
  V2ButtonProps,
  | 'size'
  | 'variant'
  | 'colour'
  | 'animate'
  | 'loading'
  | 'disabled'
  | 'className'
  | 'fullWidth'
>

const getSizeProps = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return ` py-2 px-[18px] text-body3 ${styles.sm}`
    case 'md':
      return ` py-3 sm:py-[18px] px-8 sm:px-10 text-body2 sm:text-body1 ${styles.md}`
    case 'lg':
      return ` px-14 py-5 text-h8 ${styles.lg}`
  }
}

const getStyles = (styleProps: StyleProps) => {
  const {
    size = 'md',
    variant = 'contained',
    colour = 'primary',
    animate = true,
    loading = false,
    disabled = false,
    className,
    fullWidth,
  } = styleProps

  let style = `flex items-center justify-center text-zinc-900 ${styles.button}`
  let styleBGColour = disabled ? 'bg-gray-500' : 'bg-white'
  let styleBoarderColour = disabled ? 'border-gray-500' : 'border-black'
  let spanStyle = ''

  switch (colour) {
    case 'primary':
      styleBGColour = disabled ? 'bg-gray-500' : 'bg-gold'
      styleBoarderColour = disabled
        ? 'border-gray-500'
        : variant === 'contained'
        ? 'border-gold'
        : 'border-black'
      spanStyle += ' after:border-l-black text-center w-fit'
      break
    case 'secondary':
      styleBGColour = disabled ? 'bg-gray-500' : 'bg-black'
      if (variant === 'contained') {
        spanStyle += ' text-white  after:border-l-white'
      } else if (variant === 'outlined') {
        spanStyle += ' after:border-l-black'
      } else {
        spanStyle += ' after:bg-black'
      }
      break
    case 'secondary-contrast':
      styleBoarderColour = disabled ? 'border-gray-500' : 'border-white'
      spanStyle += ' text-white '

      if (variant === 'contained') {
        spanStyle += ' after:border-l-white'
      } else if (variant === 'outlined') {
        spanStyle += ' after:border-l-white'
      } else {
        spanStyle += ' after:bg-white'
      }
      break
    case 'white':
      styleBoarderColour = disabled ? 'border-gray-500' : 'border-white'

      if (variant === 'outlined') {
        spanStyle += ' text-white '
      }

      break
  }

  switch (variant) {
    case 'contained':
      style += ` ${styleBGColour} ${styleBoarderColour} border-2 border-solid font-semibold`
      break
    case 'outlined':
      style += ` ${styleBoarderColour} bg-transparent border-2 border-solid font-bold`
      break
    default:
      style += ` bg-transparent border-none after:bg-white font-medium`
      break
  }

  style += getSizeProps(size)

  if (animate && !loading && !disabled) {
    style += ` ${variant === 'text' ? styles.up : styles.tri}`
  }

  style += ` ${fullWidth ? 'w-full' : 'w-fit'}`

  if (className != null) {
    style += ` ${className}`
  }

  return {
    style,
    styleBGColour,
    styleBoarderColour,
    spanStyle,
  }
}

const getStylesAndRest = <P extends StyleProps>(
  props: P,
): {
  styles: ReturnType<typeof getStyles>
  restAfterStyles: Omit<P, keyof StyleProps>
} => {
  const {
    size = 'md',
    variant = 'contained',
    colour = 'primary',
    animate = true,
    loading = false,
    disabled = false,
    className,
    fullWidth,
    ...restAfterStyles
  } = props
  const styles = getStyles({
    size,
    variant,
    colour,
    animate,
    loading,
    disabled,
    className,
    fullWidth,
  })
  return { styles, restAfterStyles }
}

const Link: React.FC<LinkButtonProps & CommonProps> = (props) => {
  const { styles, restAfterStyles } = getStylesAndRest(props)
  const { children, ...rest } = restAfterStyles
  const { style, spanStyle } = styles
  return (
    <NextLink className={style} {...rest}>
      <span className={spanStyle}>{children}</span>
    </NextLink>
  )
}

const ExternalLink: React.FC<ExternalLinkButtonProps & CommonProps> = (
  props,
) => {
  const { styles, restAfterStyles } = getStylesAndRest(props)
  const { children, external, ...rest } = restAfterStyles
  const { style, spanStyle } = styles
  return (
    <a className={style} {...rest}>
      <span className={spanStyle}>{children}</span>
    </a>
  )
}

const ButtonActual: React.FC<PlainButtonProps & CommonProps> = (props) => {
  const { styles, restAfterStyles } = getStylesAndRest(props)
  const { children, ...rest } = restAfterStyles
  const { style, spanStyle } = styles
  const { disabled, loading } = props
  return (
    <button className={style} {...rest} disabled={disabled}>
      <span className={spanStyle}>
        <div className='flex'>
          {loading && <Loader className='mr-3 h-8 w-8 fill-white' />}
          {children}
        </div>
      </span>
    </button>
  )
}

const Button: React.FC<V2ButtonProps> = (props) => {
  if ('external' in props) {
    return <ExternalLink {...props} />
  }
  if ('href' in props) {
    return <Link {...props} />
  }
  return <ButtonActual {...props} />
}

export default Button

export const ForwardButton = forwardRef<
  HTMLButtonElement,
  PlainButtonProps & CommonProps
>((props, ref) => {
  const { styles, restAfterStyles } = getStylesAndRest(props)
  const { children, ...rest } = restAfterStyles
  const { style, spanStyle } = styles
  const { disabled, loading } = props
  return (
    <button ref={ref} className={style} {...rest} disabled={disabled}>
      <span className={spanStyle}>
        <div className='flex'>
          {loading && <Loader className='mr-3 h-8 w-8 fill-white' />}
          {children}
        </div>
      </span>
    </button>
  )
})

export const ForwardLink = forwardRef<
  HTMLAnchorElement,
  LinkButtonProps & CommonProps
>((props, ref) => {
  const { styles, restAfterStyles } = getStylesAndRest(props)
  const { children, ...rest } = restAfterStyles
  const { style, spanStyle } = styles
  return (
    <NextLink ref={ref} className={style} {...rest}>
      <span className={spanStyle}>{children}</span>
    </NextLink>
  )
})
